/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fff;
  overflow-x: hidden;
  position: relative;
}
  body:before,
  body:after {
    content: "";
    display: block;
    top: 0px;
    bottom: 0px;
    position: absolute;
  }
  body:before {
    background: no-repeat 100% 0%/contain transparent;
    left: 0px;
    right: calc(50% + 650px);
  }
    .no-webp:before {
      background-image: url('/f/upload/body-bg-01-top-left.jpg');
    }
      .no-webp.winter-set:before {
        background-image: url('/f/upload/body-bg-01-top-left-winter.jpg');
      }
    .webp:before {
      background-image: url('/f/upload/body-bg-01-top-left.webp');
    }
      .webp.winter-set:before {
        background-image: url('/f/upload/body-bg-01-top-left-winter.webp');
      }

  body:after {
    background: no-repeat 0% 0%/contain transparent;
    left: calc(50% + 650px);
    right: 0px;
  }
    .no-webp:after {
      background-image: url('/f/upload/body-bg-01-top-right.jpg');
    }
      .no-webp.winter-set:after {
        background-image: url('/f/upload/body-bg-01-top-right-winter.jpg');
      }
    .webp:after {
      background-image: url('/f/upload/body-bg-01-top-right.webp');
    }
      .webp.winter-set:after {
        background-image: url('/f/upload/body-bg-01-top-right-winter.webp');
      }


.ffserif { font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif; }

.semibold, .semibold_a, .semibold_a * { font-weight: 500; }

span.required { color: #cc1111; font-weight: 700; }

.bgclgray, .bgclgray_h:hover { background-color: #f4f4f4; }
.bgclgray_i, .bgclgray_h_i:hover { background-color: #f4f4f4 !important; }
.bgcgray, .bgcgray_h:hover { background-color: #e5e5e5; }
.bgcgray_i, .bgcgray_h_i:hover { background-color: #e5e5e5 !important; }
.bgcmgray, .bgcmgray_h:hover { background-color: #9c9c9c; }
.bgcmgray_i, .bgcmgray_h_i:hover { background-color: #9c9c9c !important; }
.bgcdgray, .bgcdgray_h:hover { background-color: #141414; }
.bgcdgray_i, .bgcdgray_h_i:hover { background-color: #141414 !important; }
.bgclgreen, .bgclgreen_h:hover { background-color: #69a93d; }
.bgclgreen_i, .bgclgreen_h_i:hover { background-color: #69a93d !important; }
.bgcmgreen, .bgcmgreen_h:hover { background-color: #38a72e/*#299a0b*/; }
.bgcmgreen_i, .bgcmgreen_h_i:hover { background-color: #38a72e !important; }
.bgcdgreen, .bgcdgreen_h:hover { background-color: #468522; }
.bgcdgreen_i, .bgcdgreen_h_i:hover { background-color: #468522 !important; }

.clgray, .clgray_h:hover { color: #f4f4f4; }
.clgray_i, .clgray_h_i:hover { color: #f4f4f4 !important; }
.cgray, .cgray_h:hover { color: #e5e5e5; }
.cgray_i, .cgray_h_i:hover { color: #e5e5e5 !important; }
.cmgray, .cmgray_h:hover { color: #9c9c9c; }
.cmgray_i, .cmgray_h_i:hover { color: #9c9c9c !important; }
.cdgray, .cdgray_h:hover { color: #141414; }
.cdgray_i, .cdgray_h_i:hover { color: #141414 !important; }
.cred, .cred_h:hover { color: #cc1111; }
.cred_i, .cred_h_i:hover { color: #cc1111 !important; }
.clgreen, .clgreen_h:hover { color: #69a93d; }
.clgreen_i, .clgreen_h_i:hover { color: #69a93d !important; }
.cmgreen, .cmgreen_h:hover { color: #38a72e; }
.cmgreen_i, .cmgreen_h_i:hover { color: #38a72e !important; }
.cgreen, .cdgreen, .cgreen_h, .cdgreen_h:hover { color: #468522; }
.cgreen_i, .cdgreen_i, .cgreen_h_i, .cdgreen_h_i:hover { color: #468522 !important; }

.brclgray, .brclgray_h:hover { border-color: #f4f4f4; }
.brclgray_i, .brclgray_h_i:hover { border-color: #f4f4f4 !important; }
.brcgray, .brcgray_h:hover { border-color: #e5e5e5; }
.brcgray_i, .brcgray_h_i:hover { border-color: #e5e5e5 !important; }
.brcmgray, .brcmgray_h:hover { border-color: #9c9c9c; }
.brcmgray_i, .brcmgray_h_i:hover { border-color: #9c9c9c !important; }
.brcdgray, .brcdgray_h:hover { border-color: #141414; }
.brcdgray_i, .brcdgray_h_i:hover { border-color: #141414 !important; }
.brclgreen, .brclgreen_h:hover { border-color: #69a93d; }
.brclgreen_i, .brclgreen_h_i:hover { border-color: #69a93d !important; }
.brcmgreen, .brcmgreen_h:hover { border-color: #38a72e; }
.brcmgreen_i, .brcmgreen_h_i:hover { border-color: #38a72e !important; }
.brcdgreen, .brcdgreen_h:hover { border-color: #468522; }
.brcdgreen_i, .brcdgreen_h_i:hover { border-color: #468522 !important; }

p { margin-bottom: 20px; text-align: justify; }
p.before-ul { margin-bottom: 10px; }
p + p { margin-top: -6px; }

.link {
  color: #0052a4;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }


.form-block-container {
  background: #fff;
  border: 3px solid #38a72e;
  padding: 25px;
}
  .form-popup-container .form-block-container {
    min-width: 380px;
    max-width: 700px;
  }
    .form-popup-container.fancybox-confirm .form-block-container {
      min-width: unset;
    }

  .form-block-container .subsubheader {
    padding-top: 0px;
    padding-right: 20px;
  }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 18px;
    }
      .form-label {
        margin-bottom: 6px;
      }
        .form-row-checkbox .form-label {
          font-size: 14px;
        }

    .form-row-checkboxlist .form-field > div + div,
    .form-row-radiolist .form-field > div + div {
      margin: 4px 0px 0px;
    }
      .form-row-checkboxlist .form-field > div > label > span,
      .form-row-radiolist .form-field > div > label > span,
      .form-row-checkbox .form-field-wrapper {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
        .form-row-checkboxlist .form-field > div,
        .form-row-radiolist .form-field > div,
        .form-row-checkbox .form-field {
          position: relative;
        }
          .form-row-checkboxlist .form-field > div > input,
          .form-row-radiolist .form-field > div > input,
          .form-row-checkbox .form-field input {
            border: none;
            width: 0px;
            height: 0px;
            padding: 0px;
            margin: auto;
            top: 0px;
            left: 2px;
            bottom: 0px;
            position: absolute;
            opacity: 0;
            z-index: -1;
          }

          .form-row-checkboxlist .form-field > div > label > span > span:first-child,
          .form-row-radiolist .form-field > div > label > span > span:first-child,
          .form-row-checkbox .form-field > span {
            content: "";
            color: transparent;
            background: #fff;
            border: 1px solid #9c9c9c;
            font-size: 0px;
            line-height: 0px;
            display: block;
            width: 22px;
            min-width: 22px;
            height: 22px;
            position: relative;
            cursor: pointer;
          }
          .form-row-radiolist .form-field > div > label > span > span:first-child {
            border-radius: 50%;
            width: 18px;
            min-width: 18px;
            height: 18px;
            margin: 1px 1px 1px 3px;
          }
            .form-row-checkboxlist .form-field.error > div > label > span > span:first-child,
            .form-row-radiolist .form-field.error > div > label > span > span:first-child,
            .form-row-checkbox .form-field.error > span {
              border-color: #cc1111;
            }

            .form-row-checkboxlist .form-field > div > label > span > span:first-child:before,
            .form-row-radiolist .form-field > div > label > span > span:first-child:before,
            .form-row-checkbox .form-field > span label {
              content: "";
              color: transparent;
              display: block;
              font-size: 0px;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              margin: auto;
              cursor: pointer;
            }
              .form-row-checkboxlist .form-field > div > label > span > span:first-child:before,
              .form-row-checkbox .form-field > span label:after {
                content: "";
                display: none;
                background: url('/i/icon-tick.svg') no-repeat 50% 50%/12px auto transparent;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;
              }
                .form-row-checkboxlist .form-field > div > input:checked + label > span > span:first-child:before,
                .form-row-checkbox .form-field input:checked + span label:after {
                  display: block;
                }

              .form-row-radiolist .form-field > div > input:checked + label > span > span:first-child:before {
                background: #61bd4f;
                border-radius: 50%;
                margin: 4px;
              }

        .form-row-checkboxlist .form-field > div > label > span > span + span,
        .form-row-radiolist .form-field > div > label > span > span + span,
        .form-row-checkbox .form-label {
          margin: 0px 0px 0px 10px;
          cursor: pointer;
        }
          .form-row-checkbox .form-label label {
            display: block;
            cursor: pointer;
          }


      .input-container {
        background: #fff;
        border: 1px solid #9c9c9c;
        padding: 8px 10px;
        position: relative;
        z-index: 1;
      }
        .input-container.error {
          border-color: #cc1111;
        }

        .form-row-password .input-container {
          padding-right: 50px;
        }

        .input-container input,
        .input-container textarea,
        .input-container select {
          color: #141414;
          background: transparent;
          font-size: 1em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }
        .input-container select {
          background: url('/i/arrow2-down.svg') no-repeat right 10px center/10px auto transparent;
          line-height: 1;
          width: calc(100% + 20px);
          height: 38px;
          padding: 4px 30px 4px 10px;
          cursor: pointer;
          margin: -8px -10px;
          min-width: 100%;
          max-width: calc(100% + 20px);
        }


        .form-row-password .input-container .password-eye {
          background: url('/i/icon-password-eye-close.svg') no-repeat 50% 50%/70% auto #fff;
          display: block;
          margin: auto;
          top: 4px;
          right: 8px;
          bottom: 4px;
          position: absolute;
          cursor: pointer;
          z-index: 1;
        }
          .form-row-password .input-container .password-eye.opened {
            background-image: url('/i/icon-password-eye-open.svg');
          }

          .form-row-password .input-container .password-eye img {
            height: 100%;
          }


      .form-error {
        position: relative;
      }
        .form-row-checkboxlist .form-error,
        .form-row-radiolist .form-error,
        .form-row-checkbox .form-error {
          margin: 4px 0px 0px;
        }

        .errorMessage {
          color: #fff;
          background: #cc1111;
          font-size: 11px;
          line-height: 1.3;
          padding: 2px 5px 3px;
        }

      .form-row .hint {
        color: #505050;
        font-size: 13px;
        padding: 2px 0px 0px;
      }
        .form-row-checkboxlist .hint,
        .form-row-radiolist .hint,
        .form-row-checkbox .hint {
          padding-left: 32px;
        }


    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: center;
      margin: 8px 0px 0px -14px;
    }
      .form-button {
        padding: 14px 0px 0px 14px;
      }



.button-style {
  display: inline-block;
  color: #fff;
  background: #38a72e;
  border: none;
  border-radius: 0px;
  font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  padding: 7px 18px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  transition: color .2s ease 0s, background-color .2s ease 0s;
}
  .button-style[disabled],
  .button-style:disabled,
  .button-style.disabled {
    color: #959595 !important;
    background: #e7e7e7 !important;
    cursor: default;
  }

  .button-style.button-gray {
    color: #141414;
    background: #e7e7e7;
  }

  .button-style:hover,
  .button-style.active {
    color: #fff;
    background: #468522;
  }

  .button-style.button-red {
    background: #cc1111;
  }
    .button-style.button-red:hover {
      background: #a91010;
    }

  .button-style.button-yellow,
  .button-style.already-in-basket {
    background: #e7af12;
  }
    .button-style.button-yellow:hover,
    .button-style.already-in-basket:hover {
      background: #efc223;
    }

  .button-style > * {
    display: block;
    text-transform: uppercase;
    position: relative;
  }


ul.ul, ol.ol {
  margin: -8px 0px 20px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 7px;
  }
  ul.ul li, ol.ol li {
    padding-top: 8px;
    padding-left: 18px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li:before {
      content: "●";
      color: transparent;
      display: inline-block;
      background: url('/i/ul-list-style-image.svg') no-repeat 50% 50%/contain transparent;
      width: 7px;
      margin: 0px 12px 0px -18px;
    }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      color: #38a72e;
      font-weight: 700;
    }

    ul.ul li p:last-child,
    ol.ol li p:last-child {
      margin-bottom: 0px;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before,
  a.zoom-image:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;

    -webkit-transition: opacity .2s ease-in-out 0s;
    -moz-transition: opacity .2s ease-in-out 0s;
    -o-transition: opacity .2s ease-in-out 0s;
    transition: opacity .2s ease-in-out 0s;
  }

  a.zoom-image:before {
    background: #378100;
    mix-blend-mode: multiply;
  }
    a.zoom-image:hover:before {
      opacity: 0.5;
    }

  a.zoom-image:after {
    background: url('/i/icon-magnifier.svg') no-repeat 50% 50%/contain transparent;
    margin: auto;
    width: 15%;
    height: 15%;
    z-index: 1;
  }
    a.zoom-image:hover:after {
      opacity: 1;
    }


.tab-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  font-size: 15px;
  font-weight: 700;
  padding: 0px 0px 20px;
  margin: -8px 0px 0px -8px;
  white-space: nowrap;
  position: relative;
  z-index: 1;
}
  .tab-list > div {
    padding: 8px 0px 0px 8px;
  }
    .tab-list > div a,
    .tab-list > div span {
      display: block;
      border: 1px solid #e5e5e5;
      padding: 3px 10px;

      transition: color .2s ease 0s, background-color .2s ease 0s, border-color .2s ease 0s;
    }
      .tab-list > div.active a,
      .tab-list > div a:hover,
      .tab-list > div a.active,
      .tab-list > div.active span,
      .tab-list > div span:hover,
      .tab-list > div span.active {
        color: #fff;
        background: #468522;
        border-color: #468522;
      }



/* Structure */
.structure-wrapper {
  padding: 0px 10px;
  overflow: hidden;
}
  .structure-wrapper:before,
  .structure-wrapper:after {
    content: "";
    background: no-repeat;
    background-size: contain;
    display: block;
    top: 0px;
    bottom: 0px;
    position: absolute;
  }
    .no-webp .structure-wrapper:before,
    .no-webp .structure-wrapper:after {
      background-image: url('/f/upload/body-bg-02-bottom.jpg');
    }
      .no-webp.winter-set .structure-wrapper:before,
      .no-webp.winter-set .structure-wrapper:after {
        background-image: url('/f/upload/body-bg-02-bottom-winter.jpg');
      }
    .webp .structure-wrapper:before,
    .webp .structure-wrapper:after {
      background-image: url('/f/upload/body-bg-02-bottom.webp');
    }
      .webp.winter-set .structure-wrapper:before,
      .webp.winter-set .structure-wrapper:after {
        background-image: url('/f/upload/body-bg-02-bottom-winter.webp');
      }

  .structure-wrapper:before {
    background-position: 100% 100%;
    left: 0px;
    right: calc(50% + 670px);
  }
  .structure-wrapper:after {
    background-position: 0% 100%;
    left: calc(50% + 670px);
    right: 0px;
    transform: scaleX(-1);
  }

  .structure {
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(20,20,20,.3);
    max-width: 1346px;
    padding: 0px 20px;
    margin: 0px auto;
    position: relative;
    z-index: 5;
  }

  /* Header */
  .header {
    position: relative;
    z-index: 5;
  }
    .header-menu-container {
      position: relative;
      z-index: 1;
    }
      .header-menu-button-container,
      .header-menu-checkbox {
        display: none;
      }

    .header-menu {
      font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
      font-size: 14px;
      margin: 0px -20px;
    }
      .header-menu > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #468522;
        line-height: 1.1;
      }
        .header-menu > ul li,
        .account-link ul li {
          position: relative;
        }
        .header-menu > ul > li {
          flex-grow: 1;
        }
        .header-menu > ul > li + li:before {
          content: "";
          display: block;
          background: #fff;
          width: 1px;
          position: absolute;
          top: 12px;
          left: -1px;
          bottom: 12px;
        }
          .header-menu > ul .dropmarker {
            display: none;
          }

          .header-menu > ul li > div > div > a,
          .header-menu > ul li > div > div > span,
          .account-link ul li > div > div > a {
            display: block;
            text-align: left;
            white-space: nowrap;
            cursor: default;
          }
          .header-menu > ul li > div > div > a,
          .account-link ul li > div > div > a {
            cursor: pointer;
          }
          .header-menu > ul > li > div > div > a,
          .header-menu > ul > li > div > div > span {
            color: #fff;
            text-align: center;
            height: 100%;
            padding: 0px 10px;
          }
            .header-menu > ul > li.with-dropdown > div > div > a,
            .header-menu > ul > li.with-dropdown > div > div > span {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: stretch;
            }
              .header-menu > ul > li.with-dropdown > div > div > a:after,
              .header-menu > ul > li.with-dropdown > div > div > span:after {
                content: "";
                color: transparent;
                font-size: 0px;
                background: url('/i/arrow2-down.svg') no-repeat 100% 50%/10px auto transparent;
                width: 10px;
                min-width: 10px;
                padding-left: 8px;
                box-sizing: content-box;
                cursor: default;
              }
              .header-menu > ul > li.with-dropdown > div > div > a:after {
                cursor: pointer;
              }

            .header-menu > ul li > div > div > a > span,
            .header-menu > ul li > div > div > span > span,
            .account-link ul li > div > div > a > span {
              display: inline-block;
              padding: 12px 0px 7px;
              position: relative;
            }
            .header-menu > ul > li > div > div > a > span,
            .header-menu > ul > li > div > div > span > span {
              padding: 16px 0px;
            }
              .header-menu > ul li > div > div > a > span:after,
              .account-link ul li > div > div > a > span:after {
                content: "";
                background: #fff;
                display: block;
                height: 2px;
                left: 50%;
                right: 50%;
                bottom: 0px;
                position: absolute;

                transition: left .2s ease 0s, right .2s ease 0s;
              }
              .header-menu > ul li > div > div > a:hover > span:after,
              .header-menu > ul li.active > div > div > a > span:after,
              .account-link ul li > div > div > a:hover > span:after,
              .account-link ul li.active > div > div > a > span:after {
                left: 0px;
                right: 0px;
              }
              .account-link ul li > div > div > a > span:after {
                background: #38a72e;
              }


          .header-menu > ul ul {
            display: none;
          }
          .header-menu > ul.is-desktop-events ul,
          .account-link ul {
            display: block;
            background: #f4f4f4;
            border-top: 1px solid #fff;
            box-shadow: 0px 1px 6px -2px rgba(0,0,0,.2);
            text-align: left;
            padding: 8px 0px 15px;
            margin: 0px;
            min-width: 100%;
            top: 100%;
            left: 0px;
            position: absolute;
            z-index: -1;

            visibility: hidden;
            opacity: 0;

            transition: visibility 0s ease .3s, opacity .3s ease 0s;
          }
            .header-menu > ul.is-desktop-events li:hover > ul,
            .account-link:hover ul {
              visibility: visible;
              opacity: 1;

              transition: visibility 0s ease 0s, opacity .3s ease 0s;
            }

            .header-menu > ul.is-desktop-events ul li,
            .account-link ul li {
              padding: 0px 20px;
            }
              .header-menu > ul.is-desktop-events ul ul {
                top: 0px;
                left: 100%;
              }



    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0px;
      margin-left: -20px;
    }
      .header-logo {
        flex: 0 1 auto;
        line-height: 1;
        padding-left: 20px;
      }
        .header-logo img {
          width: 100%;
        }


      .header-contacts {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex: 1 1;
        margin-left: 10px;
      }
        .header-contacts > div {
          min-height: 20px;
          margin-left: 20px;
          position: relative;
        }

        .header-address-container {
          padding-left: 36px;
        }
        .header-phones-container:not(.empty),
        .header-worktime-container {
          padding-left: 38px;
        }
        .header-emails-container {
          padding-left: 40px;
        }
          .header-address-container:before,
          .header-phones-container:not(.empty):before,
          .header-emails-container:before,
          .header-worktime-container:before {
            content: "";
            background: url('/i/icon-contact-address.svg') no-repeat 50% 50%/contain transparent;
            position: absolute;
            top: 1px;
            left: 0px;
            bottom: 0px;
            width: 20px;
            height: 20px;
          }
          .header-phones-container:not(.empty):before {
            background-image: url('/i/icon-contact-phone.svg');
          }
          .header-emails-container:before {
            background-image: url('/i/icon-contact-email.svg');
            width: 22px;
          }
          .header-worktime-container:before {
            background-image: url('/i/icon-contact-worktime.svg');
          }

          .header-contacts-label,
          .header-city-link span {
            color: #505050;
            font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
            font-size: 14px;
            margin: 0px 0px 3px;
          }


          .header-city-link {
            line-height: 1.1;
            padding: 2px 0px;
          }
            .header-city-link span {
              display: inline-block;
              vertical-align: baseline;
            }

            .header-city-link a {
              display: inline-block;
              border-bottom: 1px dashed #0052a4;
              font-weight: 700;
              text-decoration: none;
              vertical-align: baseline;
              white-space: nowrap;
              margin: 0px 0px 3px;
            }
              .header-city-link a:hover {
                border-bottom-color: transparent;
              }


          .header-address,
          .header-worktime {
            font-size: 14px;
            font-weight: 700;
          }

          ul.header-address {
            margin: -4px 0px 0px;
          }
            ul.header-address li {
              padding-top: 3px;
            }

            .header-address + .contact-link {
              font-size: 14px;
              margin: 2px 0px 0px;
            }

          .header-worktime {
            white-space: nowrap;
          }


          .header-phone {
            font-size: 17px;
            font-weight: 700;
            white-space: nowrap;
          }
            .header-phone a {
              cursor: text;
            }

            .header-phones-container .whatsapp-button {
              margin: 5px 0px 0px;
            }
              .header-phones-container .whatsapp-button .button-style {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                text-transform: none;
                padding: 6px 14px 6px 12px;
              }
                .header-phones-container .whatsapp-button .button-style:before {
                  content: "";
                  background: url('/i/icon-contact-whatsapp.svg') no-repeat 0 50%/contain transparent;
                  width: 26px;
                  height: 18px;
                }


          .header-email {
            font-weight: 700;
            white-space: nowrap;
          }
            .header-email a {
              cursor: pointer;
            }
              .header-email a:hover {
                text-decoration: underline;
              }


      .header-advantages ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-content: flex-start;
        align-items: flex-start;
        font-size: 14px;
        text-align: center;
        padding: 7px 0px 0px;
        margin: -10px 0px 0px -15px;
      }
        .header-advantages ul li {
          margin-left: 15px;
        }
  /* Header end */



  /* Content block */
  .before-content-block {
    padding: 20px 0px 5px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0px;
    margin: 0px 0px 0px -20px;
    position: relative;
    z-index: 3;
  }
    .left-column {
      flex: 15 0;
      min-width: 240px;
      padding: 0px 0px 0px 20px;
      order: 1;
    }
    .left-column,
    .left-column-block {
      position: relative;
      z-index: 5;
    }
      .winter-set .left-column:before {
        content: '';
        background: no-repeat 0% 0%/contain transparent;
        display: block;
        width: 205px;
        height: 48px;
        top: -16px;
        left: 12px;
        position: absolute;
        clip-path: polygon(0 0, 100% 0, 100% 25px, 12px 25px, 10px 100%, 0 100%);
        z-index: 1;
      }
        .no-webp.winter-set .left-column:before {
          background-image: url('/i/content-snow-left.png');
        }
        .webp.winter-set .left-column:before {
          background-image: url('/i/content-snow-left.webp');
        }

      .left-column .product-menu {
        padding: 0px 0px 30px;
      }
        .left-column .product-menu > ul {
          background: #f4f4f4;
          line-height: 1.2;
        }
          .left-column .product-menu > ul > li > div > div > a {
            display: block;
            color: #fff;
            background: #141414;
            font-size: 17px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 14px 18px;
            white-space: nowrap;
            cursor: pointer;

            transition: background-color .2s ease 0s;
          }
            .left-column .product-menu > ul > li > div > div > a:hover,
            .left-column .product-menu > ul > li.active > div > div > a {
              background: #69a93d;
            }

          .left-column .product-menu > ul > li > div > .dropmarker {
            display: none;
          }


          .left-column .product-menu > ul > li > ul {
            font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
            font-size: 14px;
          }
            .left-column .product-menu > ul > li > ul > li + li {
              border-top: 1px solid #fff;
            }
              .left-column .product-menu > ul > li > ul > li a {
                display: block;
                padding: 12px 18px;

                transition: color .2s ease 0s, background-color .2s ease 0s;
              }
              .left-column .product-menu > ul > li > ul > li[class*="icn-menu-product-"] a {
                background: rgba(0,0,0,.1);
              }

                .left-column .product-menu > ul > li > ul > li a:hover,
                .left-column .product-menu > ul > li > ul > li.active a {
                  color: #fff;
                  background: #69a93d;
                }


      .left-column-block {
        padding: 0px 0px 20px;
      }
        .left-column-block > div {
          border: 1px solid #e5e5e5;
        }
          .left-column-block-title {
            font-size: 17px;
            font-weight: 700;
            line-height: 1.3;
            background: #f4f4f4;
            border-bottom: 1px solid #e5e5e5;
            text-transform: uppercase;
            padding: 14px 18px;
          }
            .left-column-block-title a {
              display: block;
              text-transform: uppercase;
              padding: 14px 18px;
              margin: -14px -18px;
              cursor: pointer;

              transition: color .2s ease 0s, background-color .2s ease 0s;
            }
              .left-column-block-title a:hover {
                color: #fff;
                background: #69a93d;
              }


          .left-column-block-button {
            border-top: 1px solid #e5e5e5;
            text-align: center;
            padding: 14px 18px;
          }
            .left-column-block-button .button-style {
              font-size: 12px;
              padding: 4px 12px;
            }


          .left-column-news,
          .left-column-products {
            position: relative;
          }
            .left-column-news:before,
            .left-column-products:before {
              content: "";
              display: block;
              height: 0px;
              padding-top: 80%;
            }
              .left-column-news.loaded:before,
              .left-column-products.loaded:before {
                display: none;
              }

            .left-column-news:after,
            .left-column-products:after {
              content: "";
              display: block;
              background: url('/i/lazy-load-placeholder.svg') no-repeat 50% 50%/80% auto #fff;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              position: absolute;
              z-index: 1;

              transition: opacity .3s ease .3s, visibility 0s ease .7s;
            }
              .left-column-news.loaded:after,
              .left-column-products.loaded:after {
                visibility: hidden;
                opacity: 0;
              }
            .left-column-news > div,
            .left-column-text-content > div {
              padding: 14px 18px;
            }
              .left-column-news > div + div,
              .left-column-text-content > div + div {
                border-top: 1px solid #e5e5e5;
              }

              .left-column-news > div > div:first-child {
                color: #38a72e;
                font-size: 13px;
                font-weight: 700;
                font-style: italic;
                padding: 0px 0px 2px;
              }
              .left-column-news > div > div + div a {
                font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
                font-size: 15px;
                cursor: pointer;
              }
                .left-column-news > div > div + div a:hover {
                  text-decoration: underline;
                }


            .left-column-products > div {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
            }
              .left-column-products > div + div {
                border-top: 1px solid #e5e5e5;
              }

              .left-column-products > div > div:first-child {
                margin: 10px;
              }
                .left-column-products > div > div:first-child a {
                  background: no-repeat 50% 0%/contain;
                  display: block;
                  width: 60px;
                  min-width: 60px;
                  max-height: 80px;
                  cursor: pointer;
                  overflow: hidden;
                }
                  .left-column-products > div > div:first-child a span {
                    display: block;
                    height: 0px;
                  }

              .left-column-products > div > div + div {
                align-self: center;
                width: 100%;
                padding: 10px 10px 10px 0px;
              }
                .left-column-products > div > div + div > div:first-child {
                  font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
                  font-size: 13px;
                  line-height: 1.3;
                  padding: 0px 0px 2px;
                }
                  .left-column-products > div > div + div > div:first-child a {
                    cursor: pointer;
                  }
                    .left-column-products > div > div + div > div:first-child a:hover,
                    .left-column-products > div > div:first-child:hover + div > div:first-child a {
                      text-decoration: underline;
                    }

                .left-column-products > div > div + div > div + div {
                  font-size: 14px;
                  vertical-align: baseline;
                }
                  .left-column-products > div > div + div > div + div span {
                    font-size: 16px;
                    font-weight: 700;
                    vertical-align: baseline;
                  }


          .left-column-text-content {
            font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
            font-size: 14px;
          }
            .left-column-text-content a {
              cursor: pointer;
            }
              .left-column-text-content a:hover {
                text-decoration: underline;
              }



    .content {
      flex: 65 1;
      max-width: 100%;
      padding: 0px 0px 0px 20px;
      position: relative;
      order: 2;
      z-index: 3;

      /*min-height: 3000px;*/
    }
      .winter-set .content:before {
        content: '';
        background: no-repeat 100% 0%/contain transparent;
        display: block;
        width: 342px;
        height: 44px;
        top: -21px;
        right: -12px;
        position: absolute;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 329px 100%, 329px 25px, 0 25px);
        z-index: 1;
      }
        .no-webp.winter-set .content:before {
          background-image: url('/i/content-snow-right.png');
        }
        .webp.winter-set .content:before {
          background-image: url('/i/content-snow-right.webp');
        }

      .content-top-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        color: #fff;
        background: #468522;
        padding-left: 10px;
        margin-left: -20px;
      }
        .content-top-panel .product-prices-button {
          align-self: center;
          padding: 8px 0px;
        }
          .content-top-panel .product-prices-button .button-style {
            background: #141414;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 14px 6px 12px;
          }
            .content-top-panel .product-prices-button .button-style:hover {
              background: #505050;
            }

            .content-top-panel .product-prices-button .button-style:before {
              content: "";
              background: url('/i/icon-prices-download.svg') no-repeat 0 50%/contain transparent;
              width: 30px;
              height: 20px;
            }


        .content-top-panel .search-form-container {
          align-self: center;
          flex-grow: 1;
          flex-shrink: 1;
          max-width: 310px;
          padding: 9px 0px 10px 20px;
        }
          .content-top-panel .search-form-wrapper {
            border-bottom: 1px solid rgba(255,255,255,.6);
            position: relative;
          }
            .content-top-panel .search-input-container {
              font-size: 14px;
              background: transparent;
              border: none;
              padding: 5px 20px 4px 2px;
            }
              .content-top-panel .search-input-container input {
                color: #fff;
                font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;;
              }
                .content-top-panel .search-input-container input[placeholder] { color: #fff; opacity: 1; }
                .content-top-panel .search-input-container input::-webkit-input-placeholder { color: #fff; opacity: 1; }
                .content-top-panel .search-input-container input:-ms-input-placeholder { color: #fff; opacity: 1; }
                .content-top-panel .search-input-container input::-moz-placeholder { color: #fff; opacity: 1; }
                .content-top-panel .search-input-container input:-moz-placeholder { color: #fff; opacity: 1; }
                .content-top-panel .search-input-container input::placeholder { color: #fff; opacity: 1; }

            .content-top-panel .search-button-container {
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 1;
            }
              .content-top-panel .search-button {
                display: inline-block;
                background: transparent;
                border: none;
                font-size: 14px;
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                min-width: 30px;
                padding: 0px 1px;
                margin: 0px;
                cursor: pointer;
              }
                .content-top-panel .search-button span {
                  color: transparent;
                  background: url('/i/icon-magnifier.svg') no-repeat 50% 50%/contain transparent;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  margin: 4px 0px;
                  z-index: 1;
                }

                .content-top-panel .search-button img {
                  height: 100%;
                }


        .header-account-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: stretch;
          padding: 0px 0px 0px 20px;
        }
          .account-link,
          .header-basket-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-left: 1px solid rgba(255,255,255,.6);
            position: relative;
          }
            .account-link > a,
            .header-basket-container > a,
            .header-basket-container > span {
              display: inline-flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.3;
              height: 100%;
              padding: 2px 18px 2px 15px;
              white-space: nowrap;

              transition: background-color .2s ease 0s;
            }

              .account-link > a:before {
                content: "";
                background: url('/i/icon-user.svg') no-repeat 0 50%/contain transparent;
                width: 28px;
                height: 27px;
              }
                .winter-set .account-link > a:before {
                  background-image: url('/i/icon-user-new-year.svg');
                }

            .account-link > a {
              font-size: 12px;
            }
              .account-link > a:hover {
                background: #69a93d;
              }

              .account-link > a > span > span + span {
                display: block;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

            .account-link ul {
              color: #141414;
              font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
              font-size: 14px;
              line-height: 1.1;
              z-index: 15;
            }
              .account-link ul .icn-menu-logout {
                padding-top: 12px;
              }
                .account-link ul .icn-menu-logout > div {
                  border-top: 1px solid #ccc;
                  padding-top: 4px;
                }


            .header-basket-container > a {
              background: #e7af12;
            }
              .header-basket-container > a:hover {
                background: #efc223/*#69a93d*/;
              }

              .header-basket-container > a:before,
              .header-basket-container > span:before {
                content: "";
                background: url('/i/icon-cart.svg') no-repeat 0 50%/contain transparent;
                width: 33px;
                height: 20px;
              }

              .header-basket-container > a > span > span + span {
                display: block;
              }


      .main-rotator-container {
        padding: 20px 0px;
        position: relative;
      }
        #main-rotator {
          padding-bottom: unset !important;
          position: relative;
          overflow: hidden;
        }
          .main-slide-container {
            display: flex !important;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            height: unset !important;
            position: relative;
          }
            .main-slide-image {
              width: 50%;
              position: relative;
              z-index: 1;
            }
              .main-slide-image > div {
                background: no-repeat 50% 50%/cover #eee;
                width: 200%;
                height: 100%;
                position: relative;
              }
                .main-slide-image > div:before {
                  content: "";
                  background: url('/i/lazy-load-placeholder.svg') no-repeat 85% 50%/auto 80% #eee;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  position: absolute;
                  z-index: 1;

                  transition: opacity .2s ease 0s;
                }
                  .main-slide-image > div.loaded:before {
                    opacity: 0;
                  }

                /*.main-slide-image > div > div {
                  max-height: 380px;
                }*/
                  .main-slide-image > div > div:before {
                    content: "";
                    display: block;
                    height: 0px;
                    padding: 35% 0% 0%;
                  }

                .main-slide-image a {
                  content: "";
                  color: transparent;
                  font-size: 0px;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 1;
                }

            .main-slide-code-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              width: 50%;
            }
              .main-slide-code-container > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 200%;
                height: 100%;
                padding: 20px 66px 25px;
              }
                .main-slide-title {
                  font-size: 28px;
                  font-weight: 700;
                  text-shadow: 0px 0px 12px rgba(255,255,255,.7);
                  position: relative;
                  z-index: 2;
                }
                  .main-slide-title a {
                    cursor: pointer;
                  }
                    .main-slide-title a:hover {
                      text-decoration: underline;
                    }

                .main-slide-code {
                  font-size: 20px;
                  text-shadow: 0px 0px 12px rgba(255,255,255,.7);
                  margin: 18px 0px 0px;
                  position: relative;
                  z-index: 2;
                }

                .white-color .main-slide-title,
                .white-color .main-slide-code {
                  color: #fff;
                  text-shadow: 0px 0px 12px rgba(0,0,0,.6);
                }

                .main-slide-button {
                  margin: 28px 0px 0px;
                  position: relative;
                  z-index: 2;
                }
                  .main-slide-button .button-style {
                    padding: 10px 18px;
                  }


          .main-rotator-container .lSAction > a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            background: none;
            width: 56px;
            height: unset;
            top: 0px;
            bottom: 0px;
            padding: 10px;
            margin-top: 0px;
            opacity: 0;

            transition: opacity .2s ease 0s;
          }
            #main-rotator:hover + .lSAction > a,
            .main-rotator-container .lSAction:hover > a {
              opacity: .5;
            }
            .main-rotator-container .lSAction > a:hover {
              opacity: 1;
            }

            .main-rotator-container .lSAction > a:after {
              content: "";
              background: no-repeat 50% 50%/14px auto #468522;
              height: 0px;
              padding-top: 100%;
            }

            .main-rotator-container .lSAction > .lSPrev {
              left: 0px;
            }
              .main-rotator-container .lSAction > .lSPrev:after {
                background-image: url('/i/arrow-left--white.svg');
              }

            .main-rotator-container .lSAction > .lSNext {
              right: 0px;
            }
              .main-rotator-container .lSAction > .lSNext:after {
                background-image: url('/i/arrow-right--white.svg');
              }


      .breadcrumbs {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
        font-size: 14px;
        line-height: 1.1;
        padding: 18px 0px 0px;
        position: relative;
      }
        .breadcrumbs li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin: 2px 0px 0px;
        }
          .breadcrumbs div,
          .breadcrumbs li:after,
          .breadcrumbs a,
          .breadcrumbs span {
            line-height: 1.2;
            vertical-align: baseline;
          }
          .breadcrumbs li:after {
            content: "/";
            color: #141414;
            line-height: 1.3;
            margin: auto 6px;
          }
            .breadcrumbs li:last-child:after {
              display: none;
            }

          .breadcrumbs a {
            color: #468522;
            cursor: pointer;

            transition: border-bottom-color .2s ease 0s;
          }
          .breadcrumbs a,
          .breadcrumbs span {
            border-bottom: 1px solid transparent;
          }
            .breadcrumbs a:hover {
              border-bottom-color: #468522;
            }


      .subheader,
      .subsubheader,
      .subsubheader-top,
      .subsubsubheader {
        font-weight: 700;
        line-height: 1.2;
        margin: 0px;
        position: relative;
        z-index: 2;
      }
        .subheader {
          font-size: 24px;
          text-transform: uppercase;
          padding: 16px 0px 20px;
        }
          .product-block-container + .subheader {
            padding-top: 25px;
          }

        .subsubheader,
        .subsubheader-top {
          font-size: 18px;
          text-transform: uppercase;
          padding: 12px 0px 15px;
        }
          .subheader + .subsubheader,
          .subsubheader-top {
            padding-top: 0px;
          }

        .subsubsubheader {
          font-size: 18px;
          padding: 4px 0px 10px;
        }


      .text-container {
        padding: 0px 0px 20px;
        position: relative;
        z-index: 1;
      }


      .incut {
        color: #fff;
        font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
        font-size: 18px;
        padding: 28px 32px 31px 32px;
        margin: 0px 0px 20px;
        position: relative;
        overflow: hidden;
      }
        .incut:before {
          content: "";
          background: url('/i/incut-bg.jpg') no-repeat 50% 60%/cover #f4f4f4;
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }
        /*.incut:after {
          content: "";
          background: rgba(0,0,0,.2);
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 0;
        }*/

        .incut > div {
          position: relative;
          z-index: 1;
        }

        .incut :last-child {
          margin-bottom: 0px;
        }


      .gallery-block-container > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;

        padding: 0px 0px 20px;
        margin: -23px 0px 0px -23px;
        position: relative;
      }
        .gallery-item {
          font-size: 14px;
          text-align: center;
          padding: 23px 0px 0px 23px;
          position: relative;
        }
          .gallery-item-width-1 {
            width: 100%;
          }
          .gallery-item-width-2 {
            width: 50%;
          }
          .gallery-item-width-3 {
            width: 33.33333333%;
          }
          .gallery-item-width-4 {
            width: 25%;
          }
          .gallery-item-width-5 {
            width: 20%;
          }

          .gallery-item img {
            width: 100%;
          }

          .gallery-item-notice {
            padding: 5px 0px 0px;
          }

      .gallery-notice {
        color: #0052a4;
        font-weight: 700;
        font-style: italic;
          margin: -15px 0px 0px !important;
          position: relative;
        }


      .list-view {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: stretch;

        padding: 0px 0px 20px;
        margin: -20px 0px 0px -20px;
        position: relative;
      }
        .list-view-item {
          width: 25%;
          padding: 20px 0px 0px 20px;
          position: relative;
        }
          .list-view-item.empty {
            color: #cc1111;
            font-weight: 700;
            width: 100% !important;
          }

          .list-view-item-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            border: 1px solid #38a72e;
            width: 100%;
            height: 100%;
            padding: 20px 20px 18px;
            position: relative;
          }
            .list-view-item-image {
              margin: 0px 0px 15px;
              position: relative;
              order: 1;
              z-index: 1;
            }
              .list-view-item-image > a,
              .list-view-item-image > span {
                display: block;
                background: no-repeat 50% 50%/contain transparent;
                height: 0px;
                padding: 60% 0% 0%;
                z-index: 1;
              }
                .list-view-item-image > a {
                  cursor: pointer;
                }

            .list-view-item-text {
              order: 2;
              z-index: 2;
            }
              .list-view-item-name {
                color: #38a72e;
                font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
                font-size: 15px;
                font-weight: 700;
                line-height: 1.3;
                text-align: center;
                position: relative;
                order: 2;
                z-index: 2;
              }
                .list-view-item-name a {
                  cursor: pointer;
                }
                  .list-view-item-name a:hover,
                  .list-view-item-image:hover + .list-view-item-name a,
                  .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                    text-decoration: underline;
                  }

              .list-view-item-notice {
                margin: 14px 0px 0px;
                order: 3;
              }
                .list-view-item-notice :last-child {
                  margin-bottom: 0px;
                }


      .list-view-line {
        padding: 0px 0px 20px;
        margin: -20px 0px 0px;
        position: relative;
      }
        .list-view-line-item {
          padding: 20px 0px 0px;
        }
          .list-view-line-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background: #f4f4f4;
            border: 1px solid #e5e5e5;
          }
            .list-view-line-item-image a,
            .list-view-line-item-image a img {
              cursor: pointer;
            }

            .list-view-line-item-text {
              width: 100%;
              padding: 20px 20px 20px 30px;
            }
              .list-view-line-item-name {
                font-size: 17px;
                font-weight: 700;
              }
                .list-view-line-item-name a {
                  cursor: pointer;
                }
                  .list-view-line-item-name a:hover,
                  .list-view-line-item-image:hover + .list-view-line-item-text .list-view-line-item-name a {
                    text-decoration: underline;
                  }

              .list-view-line-item-notice {
                font-size: 14px;
                padding: 12px 0px 0px;
              }
                .list-view-line-item-notice :last-child {
                  margin-bottom: 0px;
                }


      table.catalog-files {
        width: 100%;
        padding: 0px 0px 20px;
      }
        table.catalog-files td {
          text-align: center;
          vertical-align: middle;
          padding: 4px 10px;
        }
          table.catalog-files td:first-child {
            text-align: left;
          }
          table.catalog-files td:first-child,
          table.catalog-files thead td {
            font-weight: 700;
          }
          table.catalog-files thead td {
            font-size: 18px;
          }

          table.catalog-files tr.separator td {
            font-size: 18px;
            font-weight: 700;
            padding-top: 20px;
          }

          table.catalog-files tbody tr:first-child td,
          table.catalog-files tr.odd td {
            background: #f4f4f4;
          }


        table.catalog-files .button-style {
          white-space: nowrap;
        }


      .product-section-block-container .list-view-item-image > a,
      .info-block-container .list-view-item-image > a {
        padding-top: 72%;
      }
        .product-section-block-container .list-view-item-image > a:after,
        .info-block-container .list-view-item-image > a:after {
          content: "";
          background: url('/i/lazy-load-placeholder.svg') no-repeat 50% 50%/contain #fff;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          position: absolute;
          z-index: 1;

          transition: opacity .2s ease 0s;
        }
          .product-section-block-container .list-view-item-image > a.unveil-loaded:after,
          .info-block-container .list-view-item-image > a.unveil-loaded:after {
            opacity: 0;
          }

      .product-section-price-download {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
        .product-section-price-download > div {
          padding: 0px 0px 7px 10px;
        }
          .product-section-price-download .button-style,
          table.catalog-files .button-style {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 14px;
          }
          .product-section-price-download .button-style:before,
          table.catalog-files .button-style:before {
            content: "";
            background: url(/i/catalog-icon-pdf.svg) no-repeat 0 50%/contain transparent;
            width: 30px;
            height: 20px;
          }
          table.catalog-files td:nth-child(3) .button-style:before {
            background-image: url(/i/catalog-icon-doc.svg);
            height: 14px;
            margin: 3px 0px;
          }


      .product-labels > div {
        font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
        position: absolute;
        z-index: 15;
      }
      .product-labels > div.novelty {
        content: "";
        color: transparent;
        font-size: 0px;
        background: url('/i/product-label-novelty.svg') no-repeat 0% 0%/contain transparent;
        width: 70px;
        height: 70px;
        top: -21px;
        left: -21px;
      }

      .product-labels > div.spec {
        color: #fff;
        font-size: 12px;
        line-height: 1.2;
        background: #e7af12/*#f3b700*/;
        border-radius: 2px;
        padding: 4px 8px 5px;
        left: 0px;
        bottom: 0px;
      }

      .product-labels > div.discount {
        color: #fff;
        font-size: 13px;
        line-height: 1.2;
        text-align: center;
        background: #468522;
        min-width: 54px;
        padding: 5px 8px 5px 7px;
        margin: 0px -3px;
        top: -10px;
        right: -22px;
      }
        .product-labels > div.discount:before,
        .product-labels > div.discount:after {
          content: "";
          background: url('/i/product-label-discount.svg') no-repeat 0% 0%/contain transparent;
          top: 0px;
          bottom: 0px;
          position: absolute;
          z-index: 0;
        }
        .product-labels > div.discount:before {
          left: -3px;
          right: 0px;
        }
        .product-labels > div.discount:after {
          left: 0px;
          right: -3px;
          transform: scaleX(-1);
        }

        .product-labels > div.discount > span:first-child {
          display: none;
        }
        .product-labels > div.discount > span + span {
          position: relative;
          z-index: 1;
        }


      .product-articul {
        font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
        font-weight: 600;
        line-height: 1.2;
        vertical-align: baseline;
        padding: 8px 0px 10px;
      }
        .product-articul span {
          color: #505050;
          font-size: 14px;
          font-weight: 400;
          vertical-align: baseline;
        }

        .product-block-container .product-articul + .product-articul {
          color: #505050;
          font-size: 11px;
          padding-top: 3px;
          margin-top: -5px;
        }
          .product-block-container .product-articul + .product-articul span {
            font-size: 11px;
          }


      .product-price {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-end;
        border-top: 1px solid #e5e5e5;
        padding: 15px 0px 0px;
      }
        .product-price > div:first-child {
          font-size: 17px;
          padding: 0px 8px 0px 0px;
        }
          .product-price > div:first-child > span:first-child {
            font-size: 16px;
            display: block;
          }
          .product-price > div:first-child > span + span {
            white-space: nowrap;
          }
            .product-price > div:first-child > span + span > span {
              font-weight: 700;
            }

        .product-price > div + div {
          color: #cc1111;
          font-size: 15px;
          text-decoration: line-through;
          padding: 0px 0px 1px;
        }
          .product-price > div + div > span:first-child {
            display: none;
          }
          .product-price > div + div > span + span {
            color: #909090;
            white-space: nowrap;
          }
            .product-price > div + div > span + span > span {
              font-weight: 700;
            }


      .product-amount-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        padding: 12px 0px 0px;
        position: relative;
        z-index: 1;
      }
        .product-amount-container > div {
          flex: 1 1 auto;
        }
          .product-amount-container .button-style,
          .product-fast-order-button .button-style {
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            width: 100%;
            height: 100%;
            padding: 8px;
            white-space: nowrap;
          }

          .product-amount .input-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            background: transparent;
            border: solid #e5e5e5;
            border-width: 1px 0px 1px 1px;
            border-radius: 0px;
            height: 100%;
            padding: 0px;
          }
            .product-amount .input-container > div:first-child {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              flex-grow: 1;
              order: 2;
              height: 100%;
              position: relative;
            }
              .product-amount .input-container input {
                font-weight: 700;
                line-height: 1.4;
                padding: 4px 0px 5px;
                text-align: center;
                order: 2;
              }

            .product-amount .ui-spinner-button {
              width: 0px;
              cursor: pointer;
              position: relative;
              z-index: 1;
            }
              .product-amount .ui-spinner-button div {
                background: no-repeat 50% 50%/16px auto #f4f4f4;
                display: inline-block;
                min-width: 32px;
                height: 100%;
                position: relative;

                transition: background-color .3s ease 0s;
              }
                .product-amount .ui-spinner-button div:hover {
                  background-color: #e5e5e5;
                }

                .product-amount .ui-spinner-button img {
                  height: 100%;
                }

            .product-amount .ui-spinner-down {
              order: 1;
            }
              .product-amount .ui-spinner-down div {
                background-image: url('/i/icon-minus.svg');
              }

            .product-amount .ui-spinner-up {
              order: 3;
            }
              .product-amount .ui-spinner-up div {
                background-image: url('/i/icon-plus.svg');

                transform: translateX(-100%);
              }


      .product-block-container .list-view-item-image > a {
        padding-top: 90%;
      }
        .product-block-container .list-view-item-image > a:after {
          content: "";
          background: url('/i/lazy-load-placeholder.svg') no-repeat 50% 50%/contain #fff;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          position: absolute;
          z-index: 1;

          transition: opacity .2s ease 0s;
        }
          .product-block-container .list-view-item-image > a.unveil-loaded:after {
            opacity: 0;
          }

      .product-block-container .list-view-item-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        height: 100%;
      }
        .product-block-container .list-view-item-name {
          text-align: left;
        }


        .product-view-item-params {
          font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
          font-size: 13px;
          line-height: 1.2;
          border-top: 1px solid #e5e5e5;
          padding: 10px 0px;
        }
          .product-view-item-params > div {
            font-weight: 700;
          }
          .product-view-item-params > div + div {
            padding: 8px 0px 0px;
          }

          .product-view-item-params span {
            color: #505050;
            font-weight: 400;
          }

        .product-view-item-price-container {
          padding: 10px 0px 0px;
        }
          .product-block-container .product-amount-container {
            margin: 0px -20px -18px;
          }
            .product-block-container .product-amount .input-container {
              border-width: 1px 0px 0px;
            }



      .product-card {
        padding: 20px 0px;
      }
        .product-card > div:first-child {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 0px 0px 0px -30px;
        }
          .product-card-gallery {
            order: 1;
            width: 45%;
            max-width: 440px;
            padding: 0px 0px 20px 30px;
          }
            .product-card-gallery > div {
              position: relative;
            }
              .product-gallery {
                border: 1px solid #e5e5e5;
                position: relative;
              }
                .product-gallery-image {
                  display: block;
                  background: no-repeat 50% 50%/contain transparent;
                  height: 0px;
                  padding: 90% 0% 0%;
                  position: relative;
                }
                  .product-gallery-image > a {
                    display: block;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    position: absolute;
                    cursor: pointer;
                  }

              .product-card-gallery .lSPager {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-content: flex-start;
                align-items: stretch;
                margin: 0px 0px 0px -10px !important;
              }
                .product-card-gallery .lSPager li {
                  float: none;
                  width: 20%;
                  padding: 10px 0px 0px 10px;
                  transition: unset !important;
                }
                  .product-card-gallery .lSPager li:hover,
                  .product-card-gallery .lSPager li.active {
                    border-radius: 0px !important;
                  }

                  .product-card-gallery .lSPager li a {
                    font-size: 0px;
                    color: transparent;
                    background: no-repeat 50% 50%/contain transparent;
                    border: 2px solid #e5e5e5;
                    display: block;
                    height: 0px;
                    padding: 90% 0% 0%;
                    transition: border-color .2s ease 0s;
                  }
                    .product-card-gallery .lSPager li.active a {
                      border-color: #38a72e
                    }


            .product-card .product-labels {
              height: 0px;
              top: 0px;
              left: 0px;
              right: 0px;
              position: absolute;
              padding: 90% 0% 0%;
            }
              .product-card .product-labels > div.novelty {
                top: 0px;
                left: 0px;
              }
              .product-card .product-labels > div.spec {
                left: 15px;
                bottom: 15px;
              }
              .product-card .product-labels > div.discount {
                top: 15px;
                right: -2px;
              }


          .product-card-info {
            order: 2;
            width: 55%;
            padding: 0px 0px 20px 30px;
          }
            .product-card .product-articul {
              font-size: 17px;
              padding: 0px 0px 20px;
            }
              .product-card .product-articul span {
                font-size: 16px;
              }

            .product-card .product-price > div:first-child {
              font-size: 22px;
            }
              .product-card .product-price > div:first-child > span:first-child {
                color: #505050;
                font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
                font-size: 18px;
              }

            .product-card .product-price > div + div {
              font-size: 19px;
            }


            .product-card .product-order-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
              padding: 3px 0px 0px;
              margin: 0px 0px 0px -10px;
            }
              .product-card .product-fast-order-button {
                order: 2;
                padding: 12px 0px 0px 10px;
              }
                .product-card .product-fast-order-button .button-style {
                  color: #000;
                  background: #ccc;
                }

              .product-card .product-amount-container {
                order: 1;
                padding-left: 10px;
              }
                .product-card .product-fast-order-button + .product-amount-container {
                  width: 100%;
                }

                .product-card .product-amount-container .button-style,
                .product-card .product-fast-order-button .button-style {
                  padding: 10px 12px;
                }


            .product-card-params {
              font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
              font-size: 14px;
              border: 1px solid #e5e5e5;
              padding: 8px 20px;
              margin: 20px 0px 0px;
            }
              .product-card-params table {
                border: none;
                border-collapse: collapse;
                border-spacing: unset;
                width: 100%;
                padding: 0px;
                margin: 0px;
              }
                .product-card-params table th,
                .product-card-params table td {
                  text-align: left;
                  padding: 10px 20px 10px 0px;
                }
                  .product-card-params table tr + tr th,
                  .product-card-params table tr + tr td {
                    border-top: 1px solid #e5e5e5;
                  }

                  .product-card-params table tr th {
                    color: #505050;
                    font-weight: 400;
                  }
                  .product-card-params table tr td {
                    font-weight: 700;
                  }


      .news-block-container .list-view-line {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: stretch;
        margin-left: -20px;
      }
        .news-block-container .list-view-line-item {
          width: 50%;
          padding-left: 20px;
        }
          .news-block-container .list-view-line-wrapper {
            align-items: flex-start;
            height: 100%;
          }
            .news-block-container .list-view-line-item-text {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: stretch;
            }
              .news-block-container .list-view-line-item-name {
                order: 2;
              }
              .news-block-container .list-view-line-item-notice {
                order: 3;
              }

              .news-date {
                order: 1;
                color: #38a72e;
                font-size: 14px;
                font-weight: 700;
                font-style: italic;
                padding: 0px 0px 10px;
              }


      .pager-block-container {
        padding: 0px 0px 20px;
        position: relative;
        z-index: 2;
      }
        .pager-block-container ul.pager {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          background: #f4f4f4;
        }
          .pager-block-container ul.pager > li {
            order: 3;
          }
            .pager-block-container ul.pager > li > a {
              display: block;
              font-weight: 700;
              text-align: center;
              height: 100%;
              padding: 7px 14px;
              cursor: pointer;
              transition: color .2s ease 0s, background-color .2s ease 0s;
            }
              .pager-block-container ul.pager > li > a:hover,
              .pager-block-container ul.pager > li.active > a {
                color: #fff;
                background-color: #468522;
              }

          .pager-block-container ul.pager > li.first,
          .pager-block-container ul.pager > li.last,
          .pager-block-container ul.pager > li.previous,
          .pager-block-container ul.pager > li.next {
            width: 36px;
            order: 1;
          }
          .pager-block-container ul.pager > li.previous {
            order: 2;
          }
          .pager-block-container ul.pager > li.next {
            order: 4;
          }
          .pager-block-container ul.pager > li.last {
            order: 5;
          }
            .pager-block-container ul.pager > li.first.inactive,
            .pager-block-container ul.pager > li.last.inactive,
            .pager-block-container ul.pager > li.previous.inactive,
            .pager-block-container ul.pager > li.next.inactive {
              display: none;
            }

            .pager-block-container ul.pager > li.first > a,
            .pager-block-container ul.pager > li.last > a,
            .pager-block-container ul.pager > li.previous > a,
            .pager-block-container ul.pager > li.next > a {
              color: transparent;
              font-size: 0px;
              position: relative;
            }
              .pager-block-container ul.pager > li.first > a:before,
              .pager-block-container ul.pager > li.first > a:after,
              .pager-block-container ul.pager > li.last > a:before,
              .pager-block-container ul.pager > li.last > a:after,
              .pager-block-container ul.pager > li.previous > a:before,
              .pager-block-container ul.pager > li.previous > a:after,
              .pager-block-container ul.pager > li.next > a:before,
              .pager-block-container ul.pager > li.next > a:after {
                content: "";
                background: no-repeat 50% 50%/auto 14px transparent;
                display: block;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                position: absolute;
                opacity: 1;
                transition: opacity .2s ease 0s;
              }
              .pager-block-container ul.pager > li.first > a:hover:before,
              .pager-block-container ul.pager > li.first > a:after,
              .pager-block-container ul.pager > li.last > a:hover:before,
              .pager-block-container ul.pager > li.last > a:after,
              .pager-block-container ul.pager > li.previous > a:hover:before,
              .pager-block-container ul.pager > li.previous > a:after,
              .pager-block-container ul.pager > li.next > a:hover:before,
              .pager-block-container ul.pager > li.next > a:after {
                opacity: 0;
              }
                .pager-block-container ul.pager > li.first > a:hover:after,
                .pager-block-container ul.pager > li.last > a:hover:after,
                .pager-block-container ul.pager > li.previous > a:hover:after,
                .pager-block-container ul.pager > li.next > a:hover:after {
                  opacity: 1;
                }

              .pager-block-container ul.pager > li.first > a:before {
                background-image: url('/i/arrow-left-last.svg');
              }
              .pager-block-container ul.pager > li.first > a:after {
                background-image: url('/i/arrow-left-last--white.svg');
              }
              .pager-block-container ul.pager > li.last > a:before {
                background-image: url('/i/arrow-right-last.svg');
              }
              .pager-block-container ul.pager > li.last > a:after {
                background-image: url('/i/arrow-right-last--white.svg');
              }
              .pager-block-container ul.pager > li.previous > a:before {
                background-image: url('/i/arrow-left.svg');
              }
              .pager-block-container ul.pager > li.previous > a:after {
                background-image: url('/i/arrow-left--white.svg');
              }
              .pager-block-container ul.pager > li.next > a:before {
                background-image: url('/i/arrow-right.svg');
              }
              .pager-block-container ul.pager > li.next > a:after {
                background-image: url('/i/arrow-right--white.svg');
              }


      .info-zakaz-block,
      .incut-2 {
        padding: 0px 0px 20px;
      }
        .info-zakaz-block > div,
        .incut-2 > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          border: 1px solid #e5e5e5;
          padding: 25px 35px 20px 25px;
        }
        .incut-2 > div {
          padding: 15px 30px 8px 18px;
        }
          .info-zakaz-block > div > div:first-child,
          .incut-2 > div > div:first-child {
            content: "";
            font-size: 0px;
            color: transparent;
            background: no-repeat 50% 50%/100% auto transparent;
            border: solid transparent;
            border-width: 10px 0px 20px 20px;
            width: 15%;
            min-width: 15%;
          }
          .incut-2 > div > div:first-child {
            font-size: 0px;
            background-image: url('/i/incut-2-icon.svg');
            background-size: contain;
            border-width: 2px 0px 10px 12px;
            width: 1%;
            min-width: 7%;
            min-height: 80px;
          }

          .info-zakaz-block > div > div + div {
            width: 100%;
            padding: 0px 0px 0px 40px;
          }
          .incut-2 > div > div + div {
            width: 100%;
            padding: 0px 0px 0px 28px;
          }


      .info-delivery-cities {
        border-top: 1px solid #e5e5e5;
        padding: 0px 0px 20px;
      }
        .info-delivery-cities > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          border-bottom: 1px solid #e5e5e5;
          padding: 20px 0px;
          overflow: hidden;
        }
          .info-delivery-cities > div > div:first-child {
            color: #cc1111;
            font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
            font-size: 32px;
            font-weight: 700;
            line-height: 1;
            width: 15%;
            min-width: 15%;
            padding: 0px 30px 0px 20px
          }

          .info-delivery-cities > div > div + div {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin-top: -10px;
          }
            .info-delivery-cities > div > div + div > div {
              width: 33.33333333%;
              padding: 10px 0px 0px 20px;
            }


    .info-block-container .list-view-item {
      width: 33.33333333%;
    }


    .table-style {
      padding: 0px 0px 20px;
    }
      .table-style table {
        border: solid #38a72e;
        border-width: 0px 1px 1px;
        border-collapse: collapse;
        width: 100%;
      }
        .table-style thead th {
          color: #fff;
          background: #468522;
          font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
          font-weight: 400;
          padding: 10px 12px;
          text-align: center;
          vertical-align: bottom;
          white-space: nowrap;
        }
          .table-style thead th:first-child {
            text-align: left;
          }


        .table-style tbody td {
          padding: 12px;
          border: solid #38a72e;
          border-width: 0px 0px 1px;
          vertical-align: middle;
        }
          .table-style tbody .odd td {
            background: #f4f4f4;
          }
  /* Content block end */



  /* Basket page */
  .basket-product-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
    .basket-product-wrapper > div:first-child {
      align-self: flex-start;
      min-width: 100px;
      max-width: 0px;
      margin-right: 15px;
    }
      .basket-product-wrapper .list-view-item-image {
        margin: 0px;
      }
        .basket-product-wrapper .list-view-item-image a {
          height: unset;
          max-height: 130px;
          padding: unset;
          overflow: hidden;
        }
          .basket-product-wrapper .list-view-item-image a span {
            display: block;
            height: 0px;
          }

    .basket-product-wrapper > div + div {
      width: 100%;
    }
      .basket-product-wrapper .list-view-item-name {
        text-align: left;
      }
        .basket-product-wrapper > div:first-child:hover + div .list-view-item-name a {
          text-decoration: underline;
        }

      .basket-product-wrapper .product-view-item-params {
        font-size: 14px;
        border: none;
        padding: 15px 0px 0px;
        margin: 0px;
      }


  .basket-product-price,
  .basket-product-sum {
    text-align: center;
  }
    .basket-product-price > span,
    .basket-product-sum > span {
      vertical-align: baseline;
    }
      .basket-product-price span span,
      .basket-product-sum span span {
        vertical-align: baseline;
        white-space: nowrap;
      }

    .basket-product-sum > span:first-child {
      display: block;
      font-size: 18px;
      font-weight: 700;
    }
    .basket-product-sum > span + span {
      color: #505050;
      display: block;
      font-size: 13px;
      margin: 2px 0px 0px;
    }


  .basket-product-amount .product-amount {
    max-width: 180px;
    margin: 0px auto;
  }
    .basket-product-amount .product-amount .input-container {
      border-width: 1px;
    }


  .basket-product-delete {
    text-align: right;
  }
    .basket-product-delete a {
      content: "";
      display: block;
      color: transparent;
      background: url('/i/icon-cross.svg') no-repeat 50% 50%/auto 12px #f4f4f4;
      border: 1px solid #e5e5e5;
      border-radius: 50%;
      font-size: 0px;
      width: 34px;
      height: 34px;
      margin: 0px 0px 0px auto;
      cursor: pointer;
    }


  .basket-form {
    max-width: 580px;
    margin: 0px auto;
  }
    .basket-form .form-elements {
      padding: 0px 0px 20px;
    }
      .basket-form .form-elements .form-row-name-type_id .form-field > div + div {
        margin: 8px 0px 0px;
      }


    .basket-form .form-elements .user-profile .form-row-name-user_profile_id option[selected] {
      font-weight: 700;
    }

    .basket-form .form-elements .user-profile .table-style,
    .fast-order-form .form-elements .table-style {
      padding: 0px;
    }
      .basket-form .form-elements .user-profile .table-style table,
      .fast-order-form .form-elements .table-style table {
        border-width: 1px;
      }
        .basket-form .form-elements .user-profile .table-style td + td,
        .fast-order-form .form-elements .table-style td + td {
          border-left-width: 1px;
        }


  .basket-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
    .basket-weight-total {
      font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
      font-weight: 700;
      padding: 0px 0px 20px 131px;
    }
      .basket-weight-total span {
        color: #505050;
        font-weight: 400;
      }


    .basket-sum-total {
      font-size: 18px;
      text-align: right;
      padding: 0px 0px 20px;
    }
    #js-minimal-price-block,
    #js-delivery-price-block {
      font-size: 17px;
      text-align: right;
      padding: 0px 0px 8px;
    }

      .basket-sum-total div:first-child,
      #js-minimal-price-block div:first-child,
      #js-delivery-price-block div:first-child {
        display: inline-block;
        vertical-align: baseline;
      }

      .basket-sum-total div + div,
      #js-minimal-price-block div + div,
      #js-delivery-price-block div + div {
        display: inline-block;
        white-space: nowrap;
        vertical-align: baseline;
        margin-left: 4px;
      }
        .basket-sum-total div + div span {
          font-size: 24px;
          font-weight: 700;
          vertical-align: baseline;
        }
        #js-minimal-price-block div + div span,
        #js-delivery-price-block div + div span {
          font-weight: 700;
          vertical-align: baseline;
        }


  .basket-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 20px;
    margin: -10px 0px 0px -10px;
  }
    .basket-form .basket-buttons-container {
      flex-grow: 1;
    }

    .basket-buttons-container > div {
      padding: 10px 0px 0px 10px;
    }
      .basket-buttons-container .button-style {
        font-size: 16px;
        padding: 9px 18px;
      }

      .basket-buttons-container .return-button {
        color: #0052a4;
        font-size: 18px;
        border-bottom: 1px dashed #0052a4;
        line-height: 1.2;
        cursor: pointer;
      }
        .basket-buttons-container .return-button:hover {
          border-color: transparent;
        }


      .basket-buttons-container .goto-first-step-link a {
        font-size: 18px;
      }


  .basket-discounts-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
    .basket-discounts-container .basket-buttons-container {
      flex-direction: column-reverse;
      justify-content: flex-end;
    }

    .basket-discounts-wrapper {
      width: 100%;
      max-width: 580px;
      padding: 0px 20px 20px 0px;
    }
      .basket-discounts {
        background: #f4f4f4;
        border: 1px solid #e5e5e5;
        padding: 12px 20px 8px 20px;
      }
        .basket-discounts .subsubheader {
          font-size: 17px;
          padding: 8px 0px;
        }

        .basket-discounts .subsubheader ~ div {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: flex-end;
          padding: 10px 0px;
        }
        .basket-discounts .subsubheader ~ div + div {
          border-top: 1px solid #e5e5e5;
          vertical-align: baseline;
        }
          .basket-discounts .subsubheader ~ div > div:first-child span {
            color: #cc1111;
            font-weight: 700;
            white-space: nowrap;
          }
          .basket-discounts .subsubheader ~ div > div + div {
            flex-grow: 1;
            font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
            font-weight: 500;
            text-align: right;
            white-space: nowrap;
          }

            /*.basket-discounts .promo-code-remove-basket {
              display: inline-flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              line-height: 1.1;
              text-decoration: none;
              vertical-align: baseline;
              cursor: pointer;
            }
              .basket-discounts .promo-code-remove-basket:after {
                content: "";
                background: url('/i/icon-cross.svg') no-repeat 50% 50%/auto 8px #fff;
                border: 1px solid #e5e5e5;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                margin: 0px 0px 0px 5px;
                cursor: pointer;
              }

              .basket-discounts .promo-code-remove-basket span {
                border-bottom: 1px dashed #cc1111;

                transition: border-bottom-color .2s ease 0s;
              }
                .basket-discounts .promo-code-remove-basket:hover span {
                  border-bottom-color: transparent;
                }*/


        .basket-discounts > div.discounts-total,
        .basket-discounts > div.discounts-total > div + div {
          font-weight: 700;
        }


      /*.basket-discounts + .promo-code-form-container {
        padding-top: 20px;
      }
        .promo-code-form-container .form {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          padding: 1px 0px 0px;
        }
          .promo-code-form-container .form-row {
            width: 100%;
          }
            .promo-code-form-container .input-container {
              padding: 6px 10px;
            }

          .promo-code-form-container .form-buttons {
            margin: 0px;
          }
            .promo-code-form-container .form-button {
              padding: 0px 0px 0px 12px;
            }
            .promo-code-form-container .form-button .button-style {
              padding: 9px 20px;
            }*/
  /* Basket page end */



  /* Contact page */
  .map-container {
    height: 100%;
    position: relative;
  }
    .map-container > div {
      height: 0px;
      padding-top: 50%;
    }
      .map-container > div:before {
        content: "Загрузка карты...";
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }

      .map-container > div > div {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
      }
  /* Contact page end */



  /* User page */
  .user-flash {
    display: inline-block;
    color: #fff;
    background: #468522;
    font-weight: 700;
    padding: 9px 11px 10px 10px;
  }

  .user-base-form {
    max-width: 480px;
  }
    #user-profile-form {
      max-width: 600px;
    }
      #user-profile-form .form-row + .form-row {
        margin-top: 10px;
      }

      /*#user-profile-form .form-row-name-name,
      #user-profile-form .form-row-name-patronymic,
      #user-profile-form .form-row-name-email,
      #user-profile-form .form-row-name-delivery_address,
      #user-profile-form .form-row-name-company,
      #user-profile-form .form-row-name-company_address_fact,
      #user-profile-form .form-row-name-company_inn,
      #user-profile-form .form-row:not(.form-row-name-company_inn) + .form-row-name-company_ogrn,
      #user-profile-form .form-row-name-bank_name,
      #user-profile-form .form-row-name-bank_check_account {
        background: #f4f4f4;
      }*/

      #user-profile-form .form-row:not(.form-row-name-type):not(.form-row-checkbox) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
        #user-profile-form .form-row:not(.form-row-name-type):not(.form-row-name-default) .form-label {
          font-size: 16px;
          line-height: 1.1;
          margin: 0px 5px 0px 10px;
          width: 165px;
          min-width: 165px;
          /*white-space: nowrap;*/
        }
        #user-profile-form .form-row:not(.form-row-name-type):not(.form-row-name-delivery_package_crate):not(.form-row-name-company):not(.form-row-name-default) .form-label {
          margin-top: 6px;
        }

        #user-profile-form .form-row:not(.form-row-name-type):not(.form-row-name-default) .form-field {
          width: 100%;
        }
          #user-profile-form .input-container {
            padding-top: 5px;
            padding-bottom: 5px;
          }

        #user-profile-form .form-row-name-delivery_package_crate .form-field-wrapper {
          flex-direction: row-reverse;
        }


        #user-profile-form .form-row-name-delivery_package_crate .hint {
          padding-left: 180px;
        }


      #user-profile-form .form-buttons {
        justify-content: space-between;
      }
        #user-profile-form .form-buttons .to-list {
          width: 100%;
        }


  .user-profiles table,
  .user-orders table {
    border: none;
  }
    .user-profiles thead th,
    .user-orders thead th {
      border: 1px solid #468522;
      padding: 9px 10px;
    }
    .user-profiles thead th:first-child {
      white-space: normal;
    }
      .user-profiles thead th:first-child > span,
      .user-orders thead th:first-child > span {
        display: none;
      }


    .user-orders tbody .row-id {
      display: none;
    }
      .user-orders tbody .row-id td:first-child > span {
        font-size: 13px;
        display: none;
      }

    .user-profiles tbody td,
    .user-orders tbody td {
      border-width: 1px;
      text-align: center;
    }

    .user-profiles tbody td:first-child,
    .user-orders tbody td:first-child {
      text-align: left;
      min-width: 200px;
    }
      .user-profiles tbody td:first-child a,
      .user-orders tbody td:first-child a,
      .user-orders tbody td:first-child > span:first-child {
        display: inline-block;
        vertical-align: baseline;
      }
      .user-orders tbody td:first-child a,
      .user-orders tbody td:first-child > span:first-child {
        white-space: nowrap;
        font-size: 13px;
      }
        .user-profiles tbody td:first-child .link,
        .user-orders tbody td:first-child a > span,
        .user-orders tbody td:first-child > span:first-child > span {
          font-size: 18px;
          font-weight: 700;
          vertical-align: baseline;
        }
        .user-orders tbody td:first-child a > span:first-child {
          display: block;
        }

    .user-orders tbody td:first-child + td + td {
      font-weight: 700;
      white-space: nowrap;
    }

    .user-profiles tbody td.actions,
    .user-orders tbody td.actions {
      font-size: 14px;
      line-height: 1.5;
      width: 0%;
      padding: 5px 10px;
      white-space: nowrap;
    }
      .user-profiles tbody td.actions .js-user-profile-delete,
      .user-orders tbody td.actions .js-user-order-cancel {
        color: #cc1111;
      }

    .user-profiles tbody td.default {
      width: 0%;
      padding: 5px 10px;
    }
      .user-profiles tbody td span.default,
      .user-profiles tbody td.default span {
        content: "";
        color: transparent;
        font-size: 0px;
        background: url('/i/icon-tick.svg') no-repeat 50% 50%/12px auto #f4f4f4;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 0px;
        margin: auto;
        cursor: default;
        vertical-align: middle;
      }
      .user-profiles tbody td span.default {
        display: none;
        background-size: 10px auto;
        width: 20px;
        height: 20px;
      }
  /* User page end */



  /* Footer */
  .footer {
    margin: 0px -20px;
  }
    .footer-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      background: #e5e5e5;
      padding: 20px;
    }
      .footer-logo {
        flex: 0 1 auto;
        padding: 5px 0px 0px;
      }

      .footer-menu {
        flex: 1 1 auto;
        padding: 0px 60px 0px 50px;
      }
        .footer-menu > ul {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: flex-start;
          font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
        }
          .footer-menu > ul li {
            padding-top: 5px;
          }
          .footer-menu > ul > li {
            padding-left: 30px;
          }
            .footer-menu > ul > li > div {
              padding: 0px 0px 3px;
            }
              .footer-menu ul > li > div a {
                cursor: pointer;
              }
                .footer-menu ul > li > div a:hover,
                .footer-menu ul > li.active > div a {
                  text-decoration: underline;
                }


              .footer-menu > ul > li > div a,
              .footer-menu > ul > li > div span {
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
              }


      .footer-contact {
        color: #fff;
        padding: 0px 0px 0px 30px;
        margin: -20px -20px -20px 0px;
      }
        .footer-contact > div {
          background: #468522;
          height: 100%;
          padding: 2px 30px 20px 25px;
          position: relative;
        }
          .winter-set .footer-contact > div:before {
            content: '';
            background: no-repeat 0% 0%/contain transparent;
            display: block;
            width: 253px;
            height: 48px;
            top: -16px;
            left: -6px;
            position: absolute;
            clip-path: polygon(0 0, 100% 0, 100% 23px, 17px 23px, 17px 100%, 0 100%);
            z-index: 1;
          }
            .no-webp.winter-set .footer-contact > div:before {
              background-image: url('/i/footer-contact-snow.png');
            }
            .webp.winter-set .footer-contact > div:before {
              background-image: url('/i/footer-contact-snow.webp');
            }

          .footer-contact > div > div {
            font-weight: 700;
            min-height: 22px;
            padding: 0px 0px 0px 38px;
            margin: 18px 0px 0px;
            position: relative;
          }

          .footer-phones {
            white-space: nowrap;
          }
          .footer-emails {
            white-space: nowrap;
          }
          .footer-address ul.ul {
            margin: 0px;
          }
            .footer-address ul.ul li {
              padding-top: 0px;
              padding-left: 0px;
            }
              .footer-address ul.ul li:before {
                content: "—";
                color: #fff;
                display: inline;
                background: none;
                width: auto;
                margin: 0px 3px 0px 0px;
              }

              .footer-address ul.ul li + li {
                padding-top: 3px;
              }

            .footer-address:before,
            .footer-phones:before,
            .footer-emails:before {
              content: "";
              background: url('/i/icon-contact-address--white.svg') no-repeat 50% 50%/contain transparent;
              position: absolute;
              top: 2px;
              left: 0px;
              bottom: 0px;
              width: 20px;
              height: 20px;
            }
            .footer-phones:before {
              background-image: url('/i/icon-contact-phone--white.svg');
            }
            .footer-emails:before {
              background-image: url('/i/icon-contact-email--white.svg');
              width: 22px;
            }

            .footer-phones a {
              cursor: text;
            }

            .footer-emails a {
              cursor: pointer;
            }
              .footer-emails a:hover {
                text-decoration: underline;
              }


    .footer-bottom {
      padding: 8px 20px 20px 0px;
    }
      .footer-bottom > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
      }
        .footer-bottom > div > div {
          flex: 1 1 auto;
          padding: 12px 0px 0px 20px;
        }
  /* Footer end */


  #city-list-popup {
  }
    #city-list-popup .form-block-container {
      max-width: 1346px;
    }
      .big-cities-block {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        padding: 0px 0px 20px;
        margin: -3px 0px 0px -3px;
      }
        .big-cities-block > div {
          padding: 3px 0px 0px 3px;
        }
          .big-cities-block a {
            font-size: 13px;
            line-height: 1.2;
            text-transform: none;
            padding-left: 12px;
            padding-right: 12px;
          }


      .city-list-block,
      .city-list-block-tablet,
      .city-list-block-mobile,
      .city-list-block-mobile-small {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 14px;
        line-height: 1.3;
      }
      .city-list-block {
        margin: 0px 0px -15px;
      }
      .city-list-block-tablet,
      .city-list-block-mobile,
      .city-list-block-mobile-small {
        display: none;
        margin: 0px -10px -3px 0px;
      }

        .city-list-block > div,
        .city-list-block-tablet > div,
        .city-list-block-mobile > div,
        .city-list-block-mobile-small > div {
          flex-grow: 1;
        }
          .city-list-block > div > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0px 0px 11px;
          }
            .city-list-block > div > div:before {
              content: attr(data-letter);
              color: #909090;
              font-weight: 700;
              min-width: 1.5em;
            }

            .city-list-block > div > div > div {
              flex-grow: 1;
              padding: 0px 20px 0px 0px;
            }
            .city-list-block-tablet > div > div,
            .city-list-block-mobile > div > div,
            .city-list-block-mobile-small > div > div {
              padding: 0px 15px 4px 0px;
            }

              .city-list-block > div > div > div > div {
                padding: 0px 0px 3px;
              }
                .city-list-block > div > div > div a:hover,
                .city-list-block-tablet a:hover,
                .city-list-block-mobile a:hover,
                .city-list-block-mobile-small a:hover {
                  color: #0052a4;
                  text-decoration: underline;
                  cursor: pointer;
                }

                .city-list-block > div > div > div a.active,
                .city-list-block-tablet a.active,
                .city-list-block-mobile a.active,
                .city-list-block-mobile-small a.active {
                  display: inline-flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  color: #38a72e;
                  font-weight: 700;
                  text-decoration: none;
                  cursor: default;
                }
                  .city-list-block > div > div > div a.active:after,
                  .city-list-block-tablet a.active:after,
                  .city-list-block-mobile a.active:after,
                  .city-list-block-mobile-small a.active:after {
                    content: "";
                    display: inline-block;
                    background: url('/i/icon-tick.svg') no-repeat 50% 50% transparent;
                    width: 0.85714285em;
                    height: 0.85714285em;
                    margin: 0em 0em 0.14285714em 0.42857143em;
                  }
/* Structure end */